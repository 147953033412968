import Typograpy from "components/Typograpy";
import { motion, useAnimation } from "framer-motion";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { colors } from "theme/colors";
import { Box, FlexBox, Img } from "theme/globalStyle";
import { useInView } from "react-intersection-observer";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const ImgArea = styled(motion.div)``;

export const IntroSectionThr = () => {
  const isMobile = useMediaQuery({
    query: "(max-width : 768px)",
  });

  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: false });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <FlexBox
      $flex={1}
      $flex_direction={isMobile ? "column" : "row"}
      $isFullWidth
      $ai="center"
      $jc="center"
      $gap={isMobile ? 5 : 8}
      $minHeight={`calc(100vh - ${isMobile ? "6.5" : "8.6"}rem)`}
      $py={isMobile ? 5 : 0}
    >
      <FlexBox $flex={1} $gap={3} $ai={isMobile ? "center" : "flex-start"}>
        <Typograpy
          fontSize={isMobile ? 28 : 40}
          $lineHeight={isMobile ? 33.41 : 47.73}
          color={colors.BLACK}
          fontWeight="ExtraBold"
        >
          생애{" "}
          <Typograpy
            fontSize={isMobile ? 28 : 40}
            $lineHeight={isMobile ? 33.41 : 47.73}
            color={colors.BLUEBUTTON}
            fontWeight="ExtraBold"
          >
            월급관리
          </Typograpy>
          를 한번에
        </Typograpy>

        <Typograpy
          fontSize={isMobile ? 14 : 18}
          $lineHeight={isMobile ? 16.71 : 21.48}
          color={"#898989"}
          fontWeight="Medium"
        >
          내 또래의 소득은 얼마나 될까?
        </Typograpy>
        <Link
          to="https://irphw.app.link/UzE9vHCRaNb"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <Box
            $isPointer
            $bgcolor={colors.BLUEBUTTON}
            $br={8}
            $px={5}
            $py={isMobile ? 1.5 : 2}
          >
            <Typograpy
              fontSize={isMobile ? 14 : 16}
              $lineHeight={isMobile ? 16.71 : 19.09}
              fontWeight="Bold"
              color={colors.WHITE}
            >
              월급관리 하러가기
            </Typograpy>
          </Box>
        </Link>
      </FlexBox>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, y: 100 },
          visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
        }}
        style={{ flex: 1 }}
      >
        <FlexBox $flex={0} $ai="center" $jc="center" $flex_direction="row">
          <Img
            src={require("assets/img/intro_sec3_img.png")}
            width={"100%"}
            style={{ maxWidth: isMobile ? "32.7rem" : "unset" }}
          />
        </FlexBox>
      </motion.div>
    </FlexBox>
  );
};
