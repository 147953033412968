import Busan from "assets/img/empLogo/Busan.svg";
import TechPrice from "assets/img/empLogo/techprice.svg";

export const ShareEmpList = [
  require("assets/img/empLogo/bepa.jpg"),
  require("assets/img/empLogo/kmuholding.png"),
  require("assets/img/empLogo/kookmin_university.jpg"),
  require("assets/img/empLogo/technopark.png"),
  require("assets/img/empLogo/ic_busan_logo.jpg"),
  Busan,
  TechPrice,
];

export const dummyReviewList = [
  {
    id: "blue***",
    rank: 5,
    contents: "블루버튼 쓰니까 어렵게 손택스 할 필요가 없어요",
  },
  {
    id: "kim***",
    rank: 5,
    contents: "환급금 찾는데 수수료 낼 필요없는 앱은 블루버튼이 유일.",
  },
  {
    id: "miwa***",
    rank: 5,
    contents: "안내받은 금액 그대로 환급받았습니다.",
  },
  {
    id: "gzby***",
    rank: 5,
    contents: "환급금받으려고 설치했는데 연말정산까지 가능하네요",
  },
  {
    id: "bgjb***",
    rank: 5,
    contents: "내 월급이 또래에서 어느정도인지 볼 수 있어서 좋아요",
  },
  {
    id: "yyvd***",
    rank: 5,
    contents: "직장인들 세금관리부터 월급관리까지, 이 모든 기능들이 공짜라니!",
  },
  {
    id: "nypu***",
    rank: 5,
    contents: "내 데이터가 자동으로 쌓여서 알아서 앱이 관리해줍니다",
  },
  {
    id: "tnpm***",
    rank: 5,
    contents: "어려운 연말정산도 이젠 간편하게 앱으로!",
  },
  {
    id: "ljjd***",
    rank: 5,
    contents: "내 소득은 얼마나 되는지, 얼마나 적당히 쓰고 있는지를 한눈에.",
  },
  {
    id: "tfmw***",
    rank: 5,
    contents: " 내 돈관리는 블루버튼으로 끝!",
  },
  {
    id: "nbzj***",
    rank: 5,
    contents: "홈택스보다 훨씬 쉬움!",
  },
  {
    id: "jatm***",
    rank: 5,
    contents:
      "돈관리 잘하고 있는지 늘 궁금했는데 내 데이터에 대한 가이드 기능이 있어서 좋네요",
  },
  {
    id: "hznh***",
    rank: 5,
    contents: "복잡하지 않고 딱 필요한 기능만 있어서 쓰기 편해요",
  },
  {
    id: "roe***",
    rank: 5,
    contents: "이게 진짜 직장인을 위한 돈관리",
  },
  {
    id: "llsr***",
    rank: 5,
    contents: "전 직장 때 정보까지...현금흐름이 한눈에 보입니다",
  },
  {
    id: "diw11***",
    rank: 5,
    contents: "어렵던 세금관리를 이렇게 간단하게 할 수 있었네요",
  },
];

export const induceTypeData = {
  redbutton: {
    title: "레드버튼",
    logo: require("assets/img/redbutton_logo.png"),
    introduceList: [
      "동네병원 진료정보",
      "건강검진 분석 정보",
      "건강식품 추천 매칭",
      "AI 건강예측",
    ],
    ment: `건강데이터 관리로\n경제적, 시간적 이익!`,
    googlePlay:
      "https://play.google.com/store/apps/details?id=com.ichis_mobile&hl=ko",
    apple:
      "https://apps.apple.com/kr/app/%EB%B8%94%EB%A3%A8%EB%B2%84%ED%8A%BC/id6673894821",
    appimg1: require("assets/img/red_induce_store1.png"),
    appimg2: require("assets/img/red_induce_store2.png"),
    appimg3: require("assets/img/red_induce_store3.png"),
  },
  bluebutton: {
    title: "블루버튼",
    logo: require("assets/img/bluebutton_logo.png"),
    introduceList: [
      "연말정산 분석과 예측",
      "환급금 조회와 청구",
      "내 전체소득 확인 관리",
      "월급관리",
    ],
    ment: `복잡할 필요없이\n이제 간편하게 앱으로!`,

    googlePlay:
      "https://play.google.com/store/apps/details?id=com.ichis_mobile&hl=ko",
    apple:
      "https://apps.apple.com/kr/app/%EB%B8%94%EB%A3%A8%EB%B2%84%ED%8A%BC/id6673894821",
    appimg1: require("assets/img/induce_store1.png"),
    appimg2: require("assets/img/induce_store2.png"),
    appimg3: require("assets/img/induce_store3.png"),
  },
};
