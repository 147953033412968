import Typograpy from "components/Typograpy";
import { BlueCheckIc, RedCheckIc } from "components/icon/icon";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router";
import { FlexBox, Img } from "theme/globalStyle";

interface IntroduceBoxPropsType {
  bgColor?: string;
  contents?: string;
}

export const IntroduceBox: React.FC<IntroduceBoxPropsType> = ({
  bgColor,
  contents,
}) => {
  const { pathname } = useLocation();

  const isMobile = useMediaQuery({
    query: "(max-width : 768px)",
  });

  return (
    <FlexBox
      $flex_direction={isMobile ? "column" : "row"}
      $ai={"center"}
      $gap={isMobile ? 1.2 : 1}
      $py={isMobile ? 1.6 : 1.2}
      $px={isMobile ? 0.8 : 2}
      shadowType="normal"
      $br={10}
      $bgcolor={
        pathname === "/bluebutton" || pathname === "/" ? "#E6F0FF" : "#FFEDEC"
      }
      width={"100%"}
    >
      {pathname === "/bluebutton" || pathname === "/" ? (
        <BlueCheckIc
          width={isMobile ? 1.6 : 1.8}
          height={isMobile ? 1.6 : 1.8}
        />
      ) : (
        <RedCheckIc />
      )}
      <Typograpy
        fontWeight={isMobile ? "Medium" : "SemiBold"}
        fontSize={isMobile ? 14 : 24}
      >
        {contents}
      </Typograpy>
    </FlexBox>
  );
};
