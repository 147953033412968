import Typograpy from "components/Typograpy";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { colors } from "theme/colors";
import { Box, FlexBox, Img, MainMaxWidth } from "theme/globalStyle";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { AppleBlackIc, GooglePlayIc } from "components/icon/icon";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { IntroduceBox } from "components/box/IntroduceBox";
import { induceTypeData } from "./shareData/list";

interface InducesStorePropsType {
  type: "redbutton" | "bluebutton";
}

export const InduceStore: React.FC<InducesStorePropsType> = ({ type }) => {
  const isMobile = useMediaQuery({
    query: "(max-width : 768px)",
  });

  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: false });

  //motion 컨테이너 셋팅
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      //보여질때 설정
      opacity: 1,
      transition: {
        staggerChildren: 0.3, // 각 자식 요소 사이의 지연 시간
      },
    },
  };

  const itemVariants = {
    //각 이미지 motion 설정
    hidden: { opacity: 0, y: 50 }, //초기엔 y위치가 50
    visible: {
      opacity: 1,
      y: 0, //보여지면 정위치로 이동
      transition: {
        duration: 0.5,
      },
    },
  };

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <FlexBox
      $flex={1}
      // $pt={isMobile ? 0 : 8.6}
      $flex_direction={isMobile ? "column" : "row"}
      $isFullWidth
      $ai="center"
      $jc="center"
      // style={{
      //   background:
      //     "linear-gradient(117deg, #00B7FF 0.5%, #237BFF 36.09%, #0057DA 100.74%)",
      // }}
    >
      <FlexBox
        $flex_direction={isMobile ? "column" : "row"}
        $ai={"center"}
        $jc={"center"}
        $maxWidth={MainMaxWidth}
        $gap={isMobile ? 5 : 10}
        $pt={isMobile ? 10 : 0}
        $pb={isMobile ? 4.5 : 0}
        $px={isMobile ? 0 : 2}
      >
        <FlexBox
          $flex={0}
          $gap={isMobile ? 3 : 4}
          $ai={isMobile ? "center" : "flex-start"}
        >
          <FlexBox $gap={0.8}>
            <Typograpy fontSize={isMobile ? 13 : 20} fontWeight="Regular">
              {induceTypeData[type].title} 서비스는 모두{" "}
              <Typograpy
                fontSize={isMobile ? 13 : 20}
                color={
                  type === "redbutton" ? colors.REDBUTTON : colors.BLUEBUTTON
                }
                fontWeight={"Bold"}
              >
                무료
              </Typograpy>
            </Typograpy>
            <FlexBox
              $flex_direction="row"
              $ai={isMobile ? "center" : "center"}
              $gap={isMobile ? 1 : 1.6}
            >
              <Img
                src={induceTypeData[type].logo}
                width={isMobile ? 3.5 : 5}
                height={isMobile ? 3.5 : 5}
              />
              <Typograpy
                fontSize={isMobile ? 28 : 40}
                $lineHeight={isMobile ? 33.41 : 47.73}
                // color={"trysparent"}
                fontWeight="ExtraBold"
                // style={{
                //   background:
                //     "linear-gradient(102deg, #FFF 32.22%, #D9E7FF 67.78%)",
                //   WebkitBackgroundClip: "text",
                //   WebkitTextFillColor: "transparent",
                //   textDecoration: "none",
                // }}
              >
                {induceTypeData[type].title}
              </Typograpy>
            </FlexBox>
          </FlexBox>
          <FlexBox width={"100%"}>
            {isMobile ? (
              <FlexBox $isFullWidth $gap={1.2}>
                <FlexBox
                  $isFullWidth
                  $flex_direction="row"
                  $ai={"stretch"}
                  $gap={1.2}
                >
                  <IntroduceBox contents={"연말정산 분석과 예측"} />
                  <IntroduceBox contents={"환급금 조회와 청구"} />
                </FlexBox>
                <FlexBox
                  $isFullWidth
                  $flex_direction="row"
                  $ai={"stretch"}
                  $gap={1.2}
                >
                  <IntroduceBox contents={"내 전체소득 확인 관리"} />
                  <IntroduceBox contents={"월급관리"} />
                </FlexBox>
              </FlexBox>
            ) : (
              <FlexBox $isFullWidth $gap={1.6}>
                {induceTypeData[type].introduceList.map((intro, index) => {
                  return <IntroduceBox contents={intro} key={intro + index} />;
                })}
              </FlexBox>
            )}
          </FlexBox>
          <FlexBox $ai={isMobile ? "center" : "flex-start"} $gap={0.4}>
            <Typograpy
              fontSize={isMobile ? 24 : 40}
              fontWeight={isMobile ? "SemiBold" : "Bold"}
              style={{
                whiteSpace: "pre-wrap",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              {induceTypeData[type].ment}
            </Typograpy>
          </FlexBox>
          <FlexBox $flex_direction="row" $gap={1.2}>
            <Link
              to={induceTypeData[type].googlePlay}
              target="_blank"
              style={{ textDecoration: "none" }}
              onClick={(e) => {
                if (type === "redbutton") {
                  e.preventDefault();
                  alert("서비스 준비중입니다.");
                }
              }}
            >
              <FlexBox
                $isPointer
                $bgcolor={"#F6F7FB"}
                $br={8}
                $py={isMobile ? 1.5 : 2}
                width={isMobile ? 15 : 24}
                $ai="center"
                $jc="center"
                $flex_direction="row"
                $gap={0.8}
                $bw={2}
                $bc={"#F6F7FB"}
              >
                <GooglePlayIc
                  width={isMobile ? 1.6 : 1.8}
                  height={isMobile ? 2 : 1.8}
                />
                <Typograpy
                  fontSize={isMobile ? 14 : 16}
                  $lineHeight={isMobile ? 16.71 : 19.09}
                  fontWeight="Bold"
                  color={colors.BLACK}
                >
                  Google Play
                </Typograpy>
              </FlexBox>
            </Link>
            <Link
              to={induceTypeData[type].apple}
              target="_blank"
              style={{ textDecoration: "none" }}
              onClick={(e) => {
                if (type === "redbutton") {
                  e.preventDefault();
                  alert("서비스 준비중입니다.");
                }
              }}
            >
              <FlexBox
                $isPointer
                $bgcolor={"#F6F7FB"}
                $br={8}
                $py={isMobile ? 1.5 : 2}
                width={isMobile ? 15 : 24}
                $ai="center"
                $jc="center"
                $flex_direction="row"
                $gap={0.8}
                $bw={2}
                $bc={"#F6F7FB"}
              >
                <AppleBlackIc
                  width={isMobile ? 1.44 : 1.6}
                  height={isMobile ? 1.8 : 2}
                />
                <Typograpy
                  fontSize={isMobile ? 14 : 16}
                  $lineHeight={isMobile ? 16.71 : 19.09}
                  fontWeight="Bold"
                  color={colors.BLACK}
                >
                  App Store
                </Typograpy>
              </FlexBox>
            </Link>
          </FlexBox>
        </FlexBox>
        <FlexBox $ai={"center"} $mt={isMobile ? 2 : 0} height={"auto"}>
          <Box
            width={isMobile ? "120%" : "110%"}
            height={isMobile ? "120%" : "110%"}
            $position={"absolute"}
            $top={isMobile ? "-10%" : "-5%"}
            // $left={"-10%"}
            $br={999}
            $bgcolor={
              type === "redbutton" ? colors.REDBUTTON : colors.BLUEBUTTON
            }
          />

          {!isMobile && (
            <Box
              width={"25%"}
              height={"25%"}
              $position={"absolute"}
              $top={"-15%"}
              $left={"80%"}
              $br={999}
              $bgcolor={
                type === "redbutton" ? colors.REDBUTTON : colors.BLUEBUTTON
              }
            />
          )}
          <motion.div
            ref={ref}
            variants={containerVariants}
            initial="hidden"
            animate={controls}
            style={{ width: "80%" }}
          >
            <FlexBox $flex_direction="row" $ai="center" $gap={1.2}>
              <motion.div style={{ flex: 1 }} variants={itemVariants}>
                <Box $flex={1}>
                  <Img src={induceTypeData[type].appimg1} width={"100%"} />
                </Box>
              </motion.div>
              <FlexBox $flex={1} $gap={2}>
                <motion.div style={{ width: "100%" }} variants={itemVariants}>
                  <Box shadowType="strong" $bgcolor="white" $br={10}>
                    <Img src={induceTypeData[type].appimg2} width={"100%"} />
                  </Box>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Box shadowType="strong" $bgcolor="white" $br={10}>
                    <Img src={induceTypeData[type].appimg3} width={"100%"} />
                  </Box>
                </motion.div>
              </FlexBox>
            </FlexBox>
          </motion.div>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};
