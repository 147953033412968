import Typograpy from "components/Typograpy";
import { motion, useAnimation } from "framer-motion";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { colors } from "theme/colors";
import { Box, FlexBox, Img } from "theme/globalStyle";
import { useInView } from "react-intersection-observer";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const ImgArea = styled(motion.div)``;

export const Intro = () => {
  const isMobile = useMediaQuery({
    query: "(max-width : 768px)",
  });

  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: false });
  const [firImgMouseOver, setFirImgMouseOver] = React.useState(false);
  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <FlexBox
      $flex={1}
      $flex_direction={isMobile ? "column" : "row"}
      $isFullWidth
      $ai="center"
      $jc="center"
      $gap={isMobile ? 5 : 8}
      $minHeight={`calc(100vh - ${isMobile ? "6.5" : "8.6"}rem)`}
      $py={isMobile ? 5 : 0}
    >
      <FlexBox $flex={1} $gap={3} $ai={isMobile ? "center" : "flex-start"}>
        <Typograpy
          fontSize={isMobile ? 28 : 40}
          $lineHeight={isMobile ? 33.41 : 47.73}
          color={colors.BLACK}
          fontWeight="ExtraBold"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {`동네병원에서 진료받는\n진료정보 분석을 `}
          <Typograpy
            fontSize={isMobile ? 28 : 40}
            $lineHeight={isMobile ? 33.41 : 47.73}
            color={colors.REDBUTTON}
            fontWeight="ExtraBold"
          >
            무료
          </Typograpy>
          로
        </Typograpy>

        <Typograpy
          fontSize={isMobile ? 14 : 18}
          $lineHeight={isMobile ? 16.71 : 21.48}
          color={"#898989"}
          fontWeight="Medium"
        >
          복잡한 과정없이 한번의 클릭으로 내 건강 데이터 관리
        </Typograpy>
        <Link
          to="https://irphw.app.link/UzE9vHCRaNb"
          target="_blank"
          style={{ textDecoration: "none" }}
          onClick={(e) => {
            e.preventDefault();
            alert("서비스 준비중입니다.");
          }}
        >
          <Box
            $isPointer
            $bgcolor={colors.REDBUTTON}
            $br={8}
            $px={5}
            $py={isMobile ? 1.5 : 2}
          >
            <Typograpy
              fontSize={isMobile ? 14 : 16}
              $lineHeight={isMobile ? 16.71 : 19.09}
              fontWeight="Bold"
              color={colors.WHITE}
            >
              환급금 조회하러 가기
            </Typograpy>
          </Box>
        </Link>
      </FlexBox>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, y: 100 },
          visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
        }}
      >
        <FlexBox $flex={1} $ai="center" $jc="center" $flex_direction="row">
          <Box style={{ zIndex: firImgMouseOver ? 2 : 0 }}>
            <Img
              src={require("assets/img/red_intro_img1.png")}
              width={firImgMouseOver ? "120%" : "100%"}
              style={{ maxWidth: isMobile ? "32.7rem" : "unset" }}
              // className={"intro-img-fir"}
              onMouseEnter={() => {
                setFirImgMouseOver(true);
              }}
              onMouseLeave={() => {
                setFirImgMouseOver(false);
              }}
            />
          </Box>
          <Box $ml={-12}>
            <Img
              src={require("assets/img/red_intro_img2.png")}
              width={firImgMouseOver ? "80%" : "100%"}
              style={{ maxWidth: isMobile ? "32.7rem" : "unset" }}
            />
          </Box>
          {/* <Img
            src={require("assets/img/intro_sec2_img.png")}
            style={{ maxWidth: isMobile ? "32.7rem" : "unset" }}
            // className={"intro-img-fir"}
            width={"100%"}
          /> */}
        </FlexBox>
      </motion.div>
    </FlexBox>
  );
};
