import { Footer } from "components/footer/Footer";
import { Header } from "components/header/Header";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { Outlet, useLocation } from "react-router-dom";
import { Box, FlexBox, MainMaxWidth } from "theme/globalStyle";

{
  /** mobile은 header 사이즈만큼 줄임 */
}

const MainStatic = () => {
  const isMobile = useMediaQuery({
    query: "(max-width : 768px)",
  });

  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <FlexBox $isFullWidth $minHeight={"100vh"} $ai="center">
      {/* {pathname !== "/" && <Header />} */}
      <Header />
      <FlexBox $minHeight={"100vh"} $isFullWidth>
        <Outlet />
      </FlexBox>
      <Footer />
    </FlexBox>
  );
};

export default MainStatic;
